import React, { useState } from 'react';
import { InputBase, IconButton, Paper, Tooltip, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './SearchBar.css';

const SearchBar = ({ onSearch, onClear, initValue = '', searchType, onSearchTypeChange }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      onSearch(searchTerm, searchType);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    onClear();
  };

  const showClearIcon = searchTerm || initValue;

  return (
    <Box className="search-container">
      <Paper
        component="form"
        onSubmit={handleSubmit}
        className="search-bar"
      >
        {showClearIcon && (
          <IconButton sx={{ p: '10px' }} aria-label="clear" onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        )}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={t('searchPrompt')}
          inputProps={{ 'aria-label': t('searchPrompt') }}
          value={searchTerm || initValue}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <Tooltip title={t('searchInfo')} arrow>
          <IconButton className="icon-button">
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <RadioGroup
        row
        aria-label="search-type"
        name="search-type"
        value={searchType}
        onChange={(e) => onSearchTypeChange(e.target.value)}
        className="search-type-radio"
      >
        <FormControlLabel value="prefix" control={<Radio />} label={t('prefixSearch')} />
        <FormControlLabel value="keywords" control={<Radio />} label={t('keywordsSearch')} />
      </RadioGroup>
    </Box>
  );
};

export default SearchBar;