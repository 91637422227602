import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage';
import TopicsPage from './pages/TopicsPage';
import DynamicTitle from './components/DynamicTitle';
import Privacy from './components/Privacy';
import CopyrightDisclaimer from './components/CopyrightDisclaimer';
import PrintPage from './pages/PrintPage';
import AdPage from './pages/AdPage';

function App() {
  return (
    <Router>
      <div className="App">
        <DynamicTitle />
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/topics" element={<TopicsPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/copyright" element={<CopyrightDisclaimer />} />
          <Route path="/print" element={<PrintPage />} />
          <Route path="/ad" element={<AdPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;