import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { highlightSubject, highlightSearchTerm } from '../utils/subjectUtils';
import ShareButton from './ShareButton';
import './SubjectsTable.css';
import config from '../config';

const SubjectsTable = ({
  subjects, page, rowsPerPage, totalCount = 0, onPageChange, onRowsPerPageChange,
  onSortChange, sortField, sortOrder, topics = undefined, searchTerm = '', searchType = ''
}) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768; // Check if the device is mobile

  const location = useLocation();  
  const params = new URLSearchParams(location.search);
  const topicParam = params.get('id');  

  const [orderBy, setOrderBy] = useState(sortField || 'videoDate');
  const [order, setOrder] = useState(sortOrder || 'desc');  

  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;
  const topicsName = dataName.topics;
  const current_topic = topics?.filter(e => e[`${topicsName}_index`] === Number(topicParam));

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    onSortChange(property, newOrder);
  };  

  const subjectTopicsGroup = (topics, subject) => {
      if (!topics) {
        return '';
      }
      let str = subject[topicsName].map(
        id => topics.findLast(it => it[`${topicsName}_index`] === id)
      ).map(e => e && e.topic).join(',');

      if (current_topic?.length > 0 ) {
        const currentTopicStr = current_topic[0].topic;
        const Tag = highlightSubject(str, currentTopicStr);
        return (
          <>
            <span className="source-note">
              {Tag}
            </span>
          </>
        );
      }
      return str;
  }

  const renderSortLabel = (property, label) => (
    <TableSortLabel
      active={orderBy === property}
      direction={orderBy === property ? order : 'asc'}
      onClick={() => handleSort(property)}
      style={{
        color: orderBy === property ? '#1976d2' : 'inherit', // Blue color when active
        fontWeight: orderBy === property ? 'bold' : 'normal', // Bold when active
      }}
    >
      {label}
    </TableSortLabel>
  );  

  return (
    <TableContainer component={Paper} className="subjects-table-container">
      {totalCount > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className="table-header" style={{ width: isMobile ? '30%' : '25%' }}>
              {renderSortLabel(subjectName, t('subject'))}
            </TableCell>
            <TableCell className="table-header" style={{ width: isMobile ? '30%' : '25%' }}>
              {t('context')}
              <span className="source-note"> [from the Youtube videos]</span>
            </TableCell>
            <TableCell className="table-header" style={{ width: '20%' }}>{t('chineseTranslation')}</TableCell>
            <TableCell className="table-header" style={{ width: '20%' }}>{t('englishTranslation')}</TableCell>
            {!isMobile && <TableCell className="table-header" style={{ width: '8%' }}>{renderSortLabel('videoDate', t('videoDate'))}</TableCell>}
            {!isMobile && <TableCell className="table-header" style={{ width: '2%' }}>{t('share')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {subjects.map((subject) => (
            <TableRow key={subject.id}>
              <TableCell className="table-cell">
                <a href={subject.youtubeLink} target="_blank" rel="noopener noreferrer" className="subject-link">
                  {searchTerm && (searchType === 'prefix' || searchType === 'keywords') 
                    ? highlightSearchTerm(subject[subjectName], searchTerm, searchType)
                    : subject[subjectName]
                  }
                </a>
                <br /><span><i>{subject[`${dataName.aiFields.similarEnglishPhrase}`]}</i></span>
                {isMobile && (<ShareButton id={subject.id} subject={subject[subjectName].substring(0, 25)} />)}
                {isMobile && (
                  <>
                    <br />
                    <span>{dayjs(subject.videoDate).format('YYYY-MM-DD')}</span>
                  </>
                )}
              </TableCell>
              <TableCell className="table-cell">
                {highlightSubject(subject.context, subject[subjectName])}
                {topics ? <br /> : ''}
                {subjectTopicsGroup(topics, subject)}
              </TableCell>
              <TableCell className="table-cell">{subject[dataName.aiFields.chineseTranslation]}</TableCell>
              <TableCell className="table-cell">{subject[dataName.aiFields.englishTranslation]}</TableCell>
              {!isMobile && <TableCell className="table-cell">{dayjs(subject.videoDate).format('YYYY-MM-DD')}</TableCell>}
              {!isMobile && (
                <TableCell className="table-cell">
                  <ShareButton id={subject.id} subject={subject[subjectName].substring(0, 25)} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SubjectsTable;