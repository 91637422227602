import React from 'react';
import { Typography, Box, Link, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PrintableSubjects from '../components/PrintableSubjects';
import './WelcomePage.css';

import mainLogo from '../profoundwords-logo192.png';
import logo from '../logo256.png';

const PrintPage = () => {
  const location = useLocation();  
  const params = new URLSearchParams(location.search);
  const langParam = params.get('lang');
  const { t, i18n } = useTranslation();
  if (langParam) {
    i18n.changeLanguage(langParam);
  }    
  return ( 
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <IconButton 
          component="a" 
          href="http://profoundwords.us" 
          aria-label="home"
        >
          <img src={mainLogo} alt="Logo" style={{ height: 40, marginLeft: 30 }} />
        </IconButton> 
      </Box>    
      <Box sx={{ textAlign: 'center', mb: 1 }}>
        <Typography variant="h4" className="welcome-title">
          <Link underline='none' href='/'>
            <img src={logo} alt="Logo" style={{ height: 36, marginTop: 0 }} />
            {t('welcome')}
          </Link>
        </Typography>
      </Box>
      <div>
        <PrintableSubjects />
      </div>      
    </>   
  );
};

export default PrintPage;