import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AdPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const returnTo = location.state?.returnTo || '/';
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    // Load Google Ad
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Ad error:', e);
    }

    // Countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          navigate(returnTo);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate, returnTo]);

  return (
    <div className="ad-page">
      <h2>{t('advertisement')}</h2>
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-7142631461001751"
           data-ad-slot="8117274079"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
      <p>Returning to main page in {countdown} seconds...</p>
      <button onClick={() => navigate(returnTo)}>Return Now</button>
    </div>
  );
};

export default AdPage;