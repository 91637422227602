import React from 'react';
import { Container, Typography, Box, Paper, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const CopyrightDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('copyrightAndDisclaimer')}
        </Typography>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('dataOwnership')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('dataOwnershipContent')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('aiGeneratedContent')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('aiGeneratedContentDisclaimer')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('accuracyDisclaimer')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('accuracyDisclaimerContent')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('liabilityDisclaimer')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('liabilityDisclaimerContent')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('copyrightClaim')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('copyrightClaimContent')}
          </Typography>
        </Box>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Link component={RouterLink} to="/" sx={{ color: 'primary.main', textDecoration: 'none' }}>
            {t('home')}
          </Link>
        </Box>
      </Paper>
    </Container>
  );
};

export default CopyrightDisclaimer;