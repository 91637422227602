import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 4, textAlign: 'center', pt: 2, pb: 2 }}>
      <Typography variant="body2">
        <Link component={RouterLink} to="/privacy" className="footer-link" style={{color: '#677079'}}>
          {t('privacy')}
        </Link>
        <Link component={RouterLink} to="/copyright" className="footer-link" style={{color: '#677079'}}>
          {t('copyrightAndDisclaimer')}
        </Link>
        <Link href="mailto:rebecca25.sun@gmail.com" className="subject-link" style={{margin: '5px'}}>
          <AlternateEmailIcon fontSize='small' sx={{marginLeft: '8px'}} />{t('contactEmail')}
        </Link>
        <span><font className="subject-link" style={{marginLeft: '5px', color: '#677079'}}>Beta version</font></span>
      </Typography>
    </Box>
  );
};

export default Footer;