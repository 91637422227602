import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DynamicTitle = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('appTitle');
  }, [t, i18n.language]);

  return null;
};

export default DynamicTitle;