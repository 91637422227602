/**
 * Removes timestamps from a given string.
 * Example: "00:11:23.140 --> 00:11:24.650" will be removed.
 * @param {string} input - The input string containing timestamps.
 * @returns {string} - The string without timestamps.
 */
function removeTimestamps(input) {
  const timestampRegex = /\d{2}:\d{2}:\d{2}\.\d{3} --> \d{2}:\d{2}:\d{2}\.\d{3}/g;
  return input.replace(timestampRegex, '').trim();
}

/**
* Highlights the given subject in a text by wrapping it with a <span> element.
* @param {string} text - The text to search within.
* @param {string} subject - The subject to highlight.
* @returns {JSX.Element} - The text with the subject highlighted.
*/
export function highlightSubject(context, subject) {
  const sanitizedSubject = subject?.replace(/，/g, ' ');
  const parts = context.split(new RegExp(`(${sanitizedSubject})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === sanitizedSubject?.toLowerCase() ? (
          <span key={i} className="highlighted-subject"> {part} </span>
        ) : (
          part.length < 100 ? removeTimestamps(part) : part.substring(0, 100) + "..."
        )
      )}
    </span>
  );
}

export function highlightSearchTerm(text, searchTerm, searchType) {
  if (!searchTerm) return text;

  const regex = searchType === 'prefix' 
    ? new RegExp(`^(${searchTerm})`, 'i')
    : new RegExp(`(${searchTerm.split(' ').join('|')})`, 'gi');

  const parts = text.split(regex);
  return parts.map((part, index) => 
    regex.test(part) ? <span key={index} style={{color: "red"}}>{part}</span> : part
  );
}