const config = {
    apiUrl: 'https://situation-idioms-backend-dot-situation-idioms-deploy.uw.r.appspot.com',
    defaultLimit: 10,
    defaultOffset: 0,
    routeNames: {
        subjects: 'subjects',
        topics: 'topics'
    },
    dataNames: [
        {
            subjects: 'idioms',
            subject: 'idiom',
            topics: '',
            isSubjectToCount: "yes",
            topicsSentiments: ['positive'],
            aiFields: {
                similarEnglishPhrase: "similarEnglishPhrase",
                chineseTranslation: "chineseTranslation",
                englishTranslation: "englishTranslation",
                similarPhrases: "similarEnglishPhrase",
            },        
            aiLanguages: [
                { code: 'en', name: 'English', word: 'English' },
            ],
        }
    ],
    languages: [
        { code: 'en', name: 'English' },
        { code: 'zh', name: '中文' }
    ],
    videoWatchSeconds: 30
};
  
export default config;