import React from 'react';
import { Container, Typography, Box, Paper, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('privacyPolicy')}
        </Typography>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('informationWeCollect')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('privacyInfoCollection')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('howWeUseInformation')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('privacyInfoUsage')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('dataStorage')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('privacyDataStorage')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('dataProtection')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('privacyDataProtection')}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {t('yourRights')}
          </Typography>
          <Typography variant="body2" color="text.secondary" paragraph>
            {t('privacyYourRights')}
          </Typography>
        </Box>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Link component={RouterLink} to="/" sx={{ color: 'primary.main', textDecoration: 'none' }}>
            {t('home')}
          </Link>
        </Box>
      </Paper>
    </Container>
  );
};

export default Privacy;