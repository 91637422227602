import axios from 'axios';
import config from '../config';

const { apiUrl, routeNames } = config;

export const fetchLatestSubjects = async (page, rowsPerPage, sort = 'videoDate', order = 'desc') => {
  try {
    const offset = page * rowsPerPage;
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}?sort=${sort}&order=${order}&limit=${rowsPerPage}&offset=${offset}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest subjects:', error);
    throw error;
  }
};

export const fetchTopSubjects = async (page, rowsPerPage = 12) => {
  try {
    const offset = page * rowsPerPage;
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/top?limit=${rowsPerPage}&offset=${offset}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching top subjects:', error);
    throw error;
  }
};

export const fetchSubjectsCount = async () => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/count`);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching subjects count:', error);
    throw error;
  }
};

export const fetchTopics = async () => {
  try {
    if (config.dataNames[0].topics === '') {
      return [];
    }
    const response = await axios.get(`${apiUrl}/${routeNames.topics}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching topics:', error);
    throw error;
  }
};

export const fetchSubjectsCountByTopic = async (topicParam) => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.topics}/count?id=${topicParam}`);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching subjects count by topic:', error);
  }
}; 

export const fetchSubjectsByTopic = async (page, rowsPerPage, topicParam) => {
    try {
      const offset = page * rowsPerPage;
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/${topicParam}?sort=videoDate&limit=${rowsPerPage}&offset=${offset}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching latest subjects:', error);
      throw error;
    }
  };

export const searchSubjectById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};  

export const searchSubjects = async (searchTerm) => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/search-prefix?keyword=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};

export const searchSubjectsByKeywords = async (searchTerm) => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/search-keywords?keyword=${searchTerm}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};

export const searchSubjectsByTopic = async (searchTerm, topicParam) => {
    try {
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/search-prefix?id=${topicParam}&keyword=${searchTerm}`);
      return response.data;
    } catch (error) {
      console.error('Error searching subjects:', error);
      throw error;
    }
  };
  
export const searchSubjectsByTopicByKeywords = async (searchTerm, topicParam) => {
    try {
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/search-keywords?id=${topicParam}&keyword=${searchTerm}`);
      return response.data;
    } catch (error) {
      console.error('Error searching subjects:', error);
      throw error;
    }
  };  

export const getShareUrl = (subject, language) => {
    return `/?subject=${encodeURIComponent(subject)}&lang=${language}`;
};