export const topicsConfig = {
  1: { en: 'Happy', zh: '快樂', icon: 'SentimentVerySatisfied', color: '#FFD700', sentiment: 'positive' },
  2: { en: 'Sad', zh: '悲傷', icon: 'SentimentDissatisfied', color: '#4682B4', sentiment: 'negative' },
  3: { en: 'Angry', zh: '生氣', icon: 'SentimentDissatisfied', color: '#FF0000', sentiment: 'negative' },
  4: { en: 'Excited', zh: '興奮', icon: 'Celebration', color: '#FF69B4', sentiment: 'positive' },
  5: { en: 'Anxious', zh: '焦慮', icon: 'PanTool', color: '#FF8C00', sentiment: 'negative' },
  6: { en: 'Frustrated', zh: '沮喪', icon: 'Block', color: '#8B0000', sentiment: 'negative' },
  7: { en: 'Nervous', zh: '緊張', icon: 'WarningAmber', color: '#FFA500', sentiment: 'negative' },
  8: { en: 'Grateful', zh: '感激', icon: 'Favorite', color: '#FF1493', sentiment: 'positive' },
  9: { en: 'Disappointed', zh: '失望', icon: 'SentimentDissatisfied', color: '#708090', sentiment: 'negative' },
  10: { en: 'Content', zh: '滿足', icon: 'InsertEmoticon', color: '#98FB98', sentiment: 'positive' },
  11: { en: 'Hopeful', zh: '充滿希望', icon: 'Lightbulb', color: '#FFFF00', sentiment: 'positive' },
  12: { en: 'Proud', zh: '自豪', icon: 'EmojiEvents', color: '#FFD700', sentiment: 'positive' },
  13: { en: 'Lonely', zh: '孤獨', icon: 'Person', color: '#4682B4', sentiment: 'negative' },
  14: { en: 'Overwhelmed', zh: '不知所措', icon: 'Psychology', color: '#8A2BE2', sentiment: 'negative' },
  15: { en: 'Guilty', zh: '內疚', icon: 'Gavel', color: '#2F4F4F', sentiment: 'negative' },
  16: { en: 'Optimistic', zh: '樂觀', icon: 'WbSunny', color: '#FFA500', sentiment: 'positive' },
  17: { en: 'Jealous', zh: '嫉妒', icon: 'VisibilityOff', color: '#228B22', sentiment: 'negative' },
  18: { en: 'Nostalgic', zh: '懷舊', icon: 'History', color: '#DEB887', sentiment: 'neutral' },
  19: { en: 'Bittersweet', zh: '苦樂參半', icon: 'Balance', color: '#CD853F', sentiment: 'neutral' },
  20: { en: 'Restless', zh: '焦躁', icon: 'DirectionsRun', color: '#FF4500', sentiment: 'negative' },
  21: { en: 'Cheerful', zh: '愉快', icon: 'Mood', color: '#FFFF00', sentiment: 'positive' },
  22: { en: 'Ecstatic', zh: '狂喜', icon: 'Celebration', color: '#FF1493', sentiment: 'positive' },
  23: { en: 'Elated', zh: '興高采烈', icon: 'EmojiEmotions', color: '#FF69B4', sentiment: 'positive' },
  24: { en: 'Conflicted', zh: '矛盾', icon: 'SwapHoriz', color: '#4169E1', sentiment: 'neutral' },
  25: { en: 'Ambivalent', zh: '矛盾', icon: 'Help', color: '#708090', sentiment: 'neutral' },
  26: { en: 'Indifferent', zh: '冷漠', icon: 'SentimentNeutral', color: '#A9A9A9', sentiment: 'neutral' },
  27: { en: 'Apathetic', zh: '無動於衷', icon: 'SentimentNeutral', color: '#D3D3D3', sentiment: 'neutral' },
}